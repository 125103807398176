import { navigate } from "gatsby";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ACCOUNT_CATEGORY } from "../../commons/enum";
import { PageBody, PageBodyContent } from "../../components";
import ConnectBankModal, {
  DefaultConnectBank,
} from "../../components/connect-bank-modal";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import LinkedAccountCarousel from "../../components/linked-account-carousel";
import LinkedBank from "../../components/linked-bank";
import LinkedAccount from "../../components/linked_account";
import {
  useIsNotConnectingToAnyBank,
  useLastXDaysFinanceHistory,
  useLastXMonthsIncomesExpensesSummary,
  useLinkedBankAccountData,
} from "../../hooks/account.hooks";
import {
  useFinanceSummary,
  useIsDesktopSmall,
  useIsTablet,
} from "../../hooks/common.hooks";
import { usePropertyList } from "../../hooks/property.hooks";
import { convertToInt } from "../../utils/convert-number";
import { toTop5ExpenseChartDataAndOther } from "../../utils/expensesUtils";
import { getMedian } from "../../utils/math-util";
import ContentBodyComponent from "./components/content-body-component";
import "./scss/dashboard.scss";
import dashboardVideo from "../../videos/dashboard.mp4";

const DISPLAYED_ACCOUNT_CATEGORIES = [
  ACCOUNT_CATEGORY.ASSETS_NOT_SHARES_AND_SUPERS,
  ACCOUNT_CATEGORY.CREDIT,
  ACCOUNT_CATEGORY.LOAN,
  ACCOUNT_CATEGORY.SHARE,
  ACCOUNT_CATEGORY.SUPER,
  ACCOUNT_CATEGORY.OTHERS,
];

const toIncome = (incomesSummaryByMonth) => (month) =>
  incomesSummaryByMonth[month] || { regular: [], irregular: [] };

const toTotalRegularIncomeAmount = (income) => {
  const { regular } = income;
  return regular.reduce((total, { avgAmount }) => total + avgAmount, 0);
};

const renderAccountCombinedItem = (accountCategory) => {
  if (accountCategory !== ACCOUNT_CATEGORY.ASSETS_NOT_SHARES_AND_SUPERS) {
    return undefined;
  }
  return (
    <LinkedAccount
      cursor
      shouldHideValue
      accountId="combined"
      name="All Accounts"
      onClick={() => {
        navigate("/transactions");
      }}
    />
  );
};

const DashboardContainer = () => {
  const isTablet = useIsTablet();
  const isDesktopSmall = useIsDesktopSmall();
  const {
    data: financeHistory,
    isLoading: isLoadingFinanceHistory,
  } = useLastXDaysFinanceHistory({
    numberOfDays: 30,
  });
  const {
    income,
    expenses,
    cashDeposit,
    liabilities,
    netWealth,
    netPosition,
  } = useFinanceSummary();
  const numberOfMonthsHavePassed = new Date().getMonth();
  const {
    data: thisYearIncomesExpensesSummaryByMonth,
    isLoading: isLoadingIncomesExpensesSummary,
  } = useLastXMonthsIncomesExpensesSummary({
    numberOfMonths: numberOfMonthsHavePassed,
    numberOfLookbackMonths: 3,
    untilCurrentDate: true,
  });

  const isLoading = useMemo(() => {
    return isLoadingFinanceHistory || isLoadingIncomesExpensesSummary;
  }, [isLoadingFinanceHistory, isLoadingIncomesExpensesSummary]);

  const incomesSummaryByMonth = useMemo(() => {
    return thisYearIncomesExpensesSummaryByMonth?.incomesByLastXMonths || {};
  }, [thisYearIncomesExpensesSummaryByMonth]);

  const months = useMemo(() => {
    return Object.keys(incomesSummaryByMonth);
  }, [incomesSummaryByMonth]);

  const last3MonthsRegularIncomes = useMemo(() => {
    return months
      .slice(-3)
      .map(toIncome(incomesSummaryByMonth))
      .map(toTotalRegularIncomeAmount);
  }, [incomesSummaryByMonth, months]);

  const thisYearRegularIncomes = useMemo(() => {
    const incomes = thisYearIncomesExpensesSummaryByMonth?.incomes || 0;
    let result = 0;
    for (const [month, incomeSources] of Object.entries(incomes)) {
      if (month.startsWith(new Date().getFullYear().toString())) {
        result += incomeSources.reduce(
          (total, { amount }) => total + Number(amount),
          0
        );
      }
    }
    return result;
  }, [thisYearIncomesExpensesSummaryByMonth]);

  const avgRegularIncomeMonthly = useMemo(() => {
    return getMedian(last3MonthsRegularIncomes);
  }, [last3MonthsRegularIncomes]);

  const summaryData = useMemo(() => {
    return {
      assets: cashDeposit,
      liabilities,
      netPosition,
      avgMonthly: avgRegularIncomeMonthly,
      currentYear: thisYearRegularIncomes,
      predictedYear: avgRegularIncomeMonthly * 12,
    };
  }, [
    cashDeposit,
    liabilities,
    netPosition,
    avgRegularIncomeMonthly,
    thisYearRegularIncomes,
  ]);

  const top5LivingExpenses = useMemo(() => {
    return toTop5ExpenseChartDataAndOther(financeHistory?.expenseCategories);
  }, [financeHistory]);

  const { data: propertyList } = usePropertyList();
  const linkedBankAccountData = useLinkedBankAccountData();
  const [propertyPortfolio, setPropertyPortfolio] = useState({
    propertyValue: 0,
    currentDebt: 0,
  });

  const isNotConnectingToAnyBank = useIsNotConnectingToAnyBank();

  useEffect(() => {
    if (propertyList?.length > 0) {
      const propertyLoan = linkedBankAccountData?.propertyLoan || [];
      const currentDebt = propertyLoan.reduce((result, item) => {
        const { balance = "" } = item;
        return result + convertToInt(balance);
      }, 0);
      const propertyEstimateValue = propertyList.reduce((result, item) => {
        const { estimate = "" } = item;
        return result + Math.abs(convertToInt(estimate));
      }, 0);
      setPropertyPortfolio({
        currentDebt: Math.abs(currentDebt),
        propertyValue: propertyEstimateValue,
      });
    }
  }, [linkedBankAccountData, propertyList]);

  const deptData = useMemo(() => {
    return {
      data: [cashDeposit, liabilities],
      title: ["Cash", "Debt"],
      chartData: [netPosition, "Net Position"],
    };
  }, [cashDeposit, liabilities, netPosition]);

  const incomeChartData = useMemo(() => {
    return {
      data: [income, expenses],
      title: ["Ingoing", "Outgoing"],
      chartData: [income, "Ingoing"],
    };
  }, [income, expenses]);

  const handleLinkedAccountClicked = useCallback(
    (accountId) => {
      const isShareAccountClicked = linkedBankAccountData?.[
        ACCOUNT_CATEGORY.SHARE
      ].some((account) => account.accountId === accountId);

      const isSuperAccountClicked = linkedBankAccountData?.[
        ACCOUNT_CATEGORY.SUPER
      ].some((account) => account.accountId === accountId);

      if (isShareAccountClicked || isSuperAccountClicked) {
        navigate(`/shares-and-super?account=${accountId}`);
      } else {
        navigate(`/transactions?account=${accountId}`);
      }
    },
    [linkedBankAccountData]
  );

  return (
    <div className="page-container">
      <div className="page-content">
        {!isNotConnectingToAnyBank && !isDesktopSmall && (
          <LinkedBank
            filteredCategories={DISPLAYED_ACCOUNT_CATEGORIES}
            title="Net Wealth Position"
            value={netWealth}
            cursor
            onSelectedAccountIdChange={handleLinkedAccountClicked}
            renderAccountCombinedItem={renderAccountCombinedItem}
          />
        )}
        <PageBody>
          {!isTablet && <HeaderComponent />}
          {isNotConnectingToAnyBank ? (
            <ConnectBankModal
              videoBackground={dashboardVideo}
              callToActionButton={<DefaultConnectBank />}
            />
          ) : (
            <PageBodyContent>
              {isTablet && (
                <LinkedAccountCarousel
                  filteredCategories={DISPLAYED_ACCOUNT_CATEGORIES}
                  onSelectedAccountIdChange={handleLinkedAccountClicked}
                  renderAccountCombinedItem={renderAccountCombinedItem}
                />
              )}
              <ContentBodyComponent
                isLoading={isLoading}
                incomeData={incomeChartData}
                deptData={deptData}
                summaryData={summaryData}
                propertyPortfolio={propertyPortfolio}
                top5LivingExpenses={top5LivingExpenses}
              />
            </PageBodyContent>
          )}
        </PageBody>
      </div>
    </div>
  );
};

export default DashboardContainer;
